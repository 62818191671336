import { DiaryClientModule_$ctor } from "./DiaryClientModule.js";
import { toString, Record } from "../../fable_modules/fable-library-js.4.24.0/Types.js";
import { enum_type, int32_type, getEnumName, record_type, bool_type, list_type, class_type, string_type, lambda_type, int64_type } from "../../fable_modules/fable-library-js.4.24.0/Reflection.js";
import { Router_modifyLocation, Route, Route_$reflection } from "../../Omnicv.Client.Common/Router.js";
import { dateToStrForDiary, secondsToHuman, countWordsEx } from "../../Omnicv.Client.Common/Utils.js";
import { value as value_2, defaultArg } from "../../fable_modules/fable-library-js.4.24.0/Option.js";
import { Fa_ISize, Fa_IconOption, Fa_i } from "../../fable_modules/Fable.FontAwesome.3.0.0/FontAwesome.fs.js";
import { length, last as last_1, head, singleton as singleton_1, isEmpty, empty, map, ofArray } from "../../fable_modules/fable-library-js.4.24.0/List.js";
import { empty as empty_1, map as map_1, singleton, append, delay, toList } from "../../fable_modules/fable-library-js.4.24.0/Seq.js";
import { list as list_1, label, menu, Item_a, Item_li, Item_Option } from "../../fable_modules/Fulma.3.0.0/Components/Menu.fs.js";
import { contains } from "../../fable_modules/fable-library-js.4.24.0/Set.js";
import { toClientListView, TagTreeNode__get_IsLeaf } from "../../Omnicv.Shared.Common/TagUtils.js";
import { createElement } from "react";
import * as react from "react";
import { reactApi } from "../../fable_modules/Feliz.2.9.0/Interop.fs.js";
import { Option, tag as tag_5 } from "../../fable_modules/Fulma.3.0.0/Elements/Tag.fs.js";
import { Common_GenericOption, Modifier_IModifier, TextSize_Option, Screen, Text_span, Size_ISize, Color_IColor } from "../../fable_modules/Fulma.3.0.0/Common.fs.js";
import { createObj } from "../../fable_modules/fable-library-js.4.24.0/Util.js";
import { Router_href } from "../../Omnicv.Client.Common/Router.js";
import { RatingStarsProps, ratingStars as ratingStars_1 } from "../../Omnicv.Client.Common/Components/RatingStars.js";
import { EventInfo__BestLocation, EventInfo__GetLocationTz, EventInfo__DisplayTimeZone } from "../../Omnicv.Shared.Common/CoreEventInfo.js";
import * as common from "../../../src/javascript/common.js";
import { isNullOrWhiteSpace } from "../../fable_modules/fable-library-js.4.24.0/String.js";
import { Footer_p, Footer_div, footer, content as content_1, Header_icon, Header_Title_Option, Header_title, header, card } from "../../fable_modules/Fulma.3.0.0/Components/Card.fs.js";
import { Option as Option_1, button } from "../../fable_modules/Fulma.3.0.0/Elements/Button.fs.js";
import { Option as Option_2, icon } from "../../fable_modules/Fulma.3.0.0/Elements/Icon.fs.js";
import { Fa_i as Fa_i_1 } from "../../fable_modules/Fable.FontAwesome.3.0.0/FontAwesome.fs.js";
import { Icons__ToIcon } from "../../Omnicv.Client.Common/SharedView.js";
import { Icons } from "../../Omnicv.Client.Common/SharedView.js";
import { content as content_2 } from "../../fable_modules/Fulma.3.0.0/Elements/Content.fs.js";
import { Markdown_ToHtml_1BFEAEDB } from "../../fable_modules/Fable.Formatting.Markdown.1.0.1/Markdown.fs.js";
import { title as title_1, heading, Item_Option as Item_Option_1, item, level } from "../../fable_modules/Fulma.3.0.0/Layouts/Level.fs.js";
import { body, header as header_1, Option as Option_3, message } from "../../fable_modules/Fulma.3.0.0/Components/Message.fs.js";
import { Option as Option_4, columns } from "../../fable_modules/Fulma.3.0.0/Layouts/Columns.fs.js";
import { Option as Option_5, column } from "../../fable_modules/Fulma.3.0.0/Layouts/Column.fs.js";
import { defaultOf } from "../../fable_modules/fable-library-js.4.24.0/Util.js";
import { days } from "../../fable_modules/fable-library-js.4.24.0/TimeSpan.js";
import { compare, date as date_1, op_Subtraction } from "../../fable_modules/fable-library-js.4.24.0/Date.js";
import { Class, closeable } from "../../Omnicv.Client.Common/Components/Notification.js";
import { formatDistance } from "date-fns";
import { box$0027 } from "../../fable_modules/Fulma.3.0.0/Elements/Box.fs.js";
import { Option as Option_6, h4, h3 } from "../../fable_modules/Fulma.3.0.0/Elements/Heading.fs.js";

export const diaryModule = DiaryClientModule_$ctor();

export class DisplayRecordData$1 extends Record {
    constructor(EditFun, TaglineFun, TagsFun, DisplayFun, AddDayLink) {
        super();
        this.EditFun = EditFun;
        this.TaglineFun = TaglineFun;
        this.TagsFun = TagsFun;
        this.DisplayFun = DisplayFun;
        this.AddDayLink = AddDayLink;
    }
}

export function DisplayRecordData$1_$reflection(gen0) {
    return record_type("Omnicv.Client.Diary.ClientUtils.DisplayRecordData`1", [gen0], DisplayRecordData$1, () => [["EditFun", lambda_type(int64_type, Route_$reflection())], ["TaglineFun", lambda_type(gen0, string_type)], ["TagsFun", lambda_type(gen0, string_type)], ["DisplayFun", lambda_type(gen0, list_type(class_type("Fable.React.ReactElement", undefined)))], ["AddDayLink", bool_type]]);
}

function countDiaryWords(record) {
    return countWordsEx([record.Tagline, record.Notes]);
}

function countReviewWords(record) {
    return countWordsEx([record.Tagline, record.Notes]);
}

function countInteractionWords(record) {
    return countWordsEx([record.Tagline, record.Notes, record.Participants, defaultArg(record.FollowupNotes, "")]);
}

export function countWordsInRecord(record) {
    switch (record.tag) {
        case 1:
            return countReviewWords(record.fields[0]) | 0;
        case 2:
            return countInteractionWords(record.fields[0]) | 0;
        default:
            return countDiaryWords(record.fields[0]) | 0;
    }
}

export function renderTagTree(onClick, input, currentTags) {
    const leafIcon = Fa_i(ofArray([new Fa_IconOption(11, ["fas fa-tag"]), new Fa_IconOption(0, [new Fa_ISize(0, [])])]), []);
    const parentIcon = Fa_i(ofArray([new Fa_IconOption(11, ["fas fa-tags"]), new Fa_IconOption(0, [new Fa_ISize(0, [])])]), []);
    const options = (tag, isActive) => toList(delay(() => append(singleton(new Item_Option(0, [isActive])), delay(() => (contains(tag, currentTags) ? singleton(new Item_Option(2, ["tag-menu-chosen"])) : singleton(new Item_Option(3, [(_arg) => {
        onClick(tag);
    }])))))));
    const treeToMenu = (tree) => {
        if (TagTreeNode__get_IsLeaf(tree)) {
            return Item_li(options(tree.Tag, false), ofArray([createElement("span", {
                children: reactApi.Children.toArray([leafIcon]),
            }), " " + tree.Caption]));
        }
        else {
            const arg_3 = map(treeToMenu, tree.Children);
            return ((caption_1) => ((isActive_2) => ((children_2) => {
                const children_7 = [Item_a(options(tree.Tag, isActive_2), ofArray([createElement("span", {
                    children: reactApi.Children.toArray([parentIcon]),
                }), " ", caption_1])), react.createElement("ul", {}, ...children_2)];
                return react.createElement("li", {}, ...children_7);
            })))(tree.Caption)(false)(arg_3);
        }
    };
    return menu(empty(), ofArray([label(empty(), singleton_1(isEmpty(input) ? "No frequent tags yet" : "Frequently used tags")), list_1(empty(), toList(delay(() => map_1(treeToMenu, input))))]));
}

export function renderTag(tag) {
    return tag_5(ofArray([new Option(1, [new Color_IColor(6, [])]), new Option(3, []), new Option(0, [new Size_ISize(2, [])])]), singleton_1(createElement("a", createObj(ofArray([["children", tag], Router_href(new Route(16, [tag]))])))));
}

export function ratingStars(value, callback) {
    return ratingStars_1(ofArray([new RatingStarsProps(1, [5]), new RatingStarsProps(4, [24]), new RatingStarsProps(5, [value / 2]), new RatingStarsProps(3, [true]), new RatingStarsProps(2, [callback != null]), new RatingStarsProps(6, [(newValue) => {
        if (callback == null) {
        }
        else {
            callback(~~(newValue * 2));
        }
    }])]));
}

export function displayEntry(editFun, deleteFun, eventInfo, record, tagline, tags, displayContent, wordCount) {
    let arg_1;
    let timestamp;
    const temp = `${eventInfo.EventTimestamp} | ${EventInfo__DisplayTimeZone(eventInfo)}`;
    const currentTz = common.getTimezoneName();
    const locationTz = EventInfo__GetLocationTz(eventInfo);
    timestamp = (((locationTz != null) && (value_2(locationTz) !== currentTz)) ? (temp + (` | ${((arg_1 = value_2(locationTz), common.dateToLuxonTz(eventInfo.EventTimestamp, arg_1))).toRFC2822()}`)) : temp);
    let patternInput;
    const wordCountInfo = `Words: ${wordCount}`;
    patternInput = (isNullOrWhiteSpace(tagline) ? [timestamp, wordCountInfo] : [tagline, (wordCountInfo + " | ") + timestamp]);
    return card(empty(), toList(delay(() => append(singleton(header(empty(), singleton_1(Header_title(singleton_1(new Header_Title_Option(0, [])), singleton_1(patternInput[0]))))), delay(() => {
        const location = EventInfo__BestLocation(eventInfo);
        return append((location !== "") ? singleton(header(empty(), ofArray([Header_icon(empty(), singleton_1(Fa_i(singleton_1(new Fa_IconOption(11, ["fas fa-map-marker-alt"])), []))), Header_title(empty(), singleton_1(location))]))) : empty_1(), delay(() => append(singleton(content_1(empty(), displayContent(record))), delay(() => append(singleton(footer(empty(), singleton_1(Footer_div(empty(), singleton_1(Text_span(singleton_1(new Common_GenericOption(2, [singleton_1(new Modifier_IModifier(3, [new Screen(0, []), new TextSize_Option(6, [])]))])), singleton_1(patternInput[1]))))))), delay(() => singleton(footer(empty(), toList(delay(() => {
            let children;
            return append(singleton(Footer_p(empty(), singleton_1((children = map(renderTag, toClientListView(tags)), react.createElement("div", {
                className: "tags",
            }, ...children))))), delay(() => singleton(Footer_div(empty(), ofArray([button(ofArray([new Option_1(0, [new Color_IColor(4, [])]), new Option_1(5, []), new Option_1(18, [(_arg) => {
                Router_modifyLocation(editFun(eventInfo.Id));
            }])]), singleton_1(icon(singleton_1(new Option_2(0, [new Size_ISize(0, [])])), singleton_1(Fa_i_1(singleton_1(Icons__ToIcon(new Icons(4, []))), []))))), button(ofArray([new Option_1(0, [new Color_IColor(8, [])]), new Option_1(5, []), new Option_1(18, [(_arg_1) => {
                if (window.confirm(`${"Confirm deletion"}:
${"Are you sure you want to delete this diary entry"}`)) {
                    (() => {
                        deleteFun(eventInfo.Id);
                    })();
                }
            }])]), singleton_1(icon(singleton_1(new Option_2(0, [new Size_ISize(0, [])])), singleton_1(Fa_i_1(singleton_1(Icons__ToIcon(new Icons(2, []))), [])))))])))));
        }))))))))));
    })))));
}

export function displayDiaryContent(record) {
    return singleton_1(content_2(empty(), singleton_1(createElement("div", {
        dangerouslySetInnerHTML: {
            __html: Markdown_ToHtml_1BFEAEDB(record.Notes),
        },
    }))));
}

export function displayReviewContent(record) {
    return ofArray([content_2(empty(), singleton_1(ratingStars(~~record.Rating, undefined))), content_2(empty(), singleton_1(createElement("div", {
        dangerouslySetInnerHTML: {
            __html: Markdown_ToHtml_1BFEAEDB(record.Notes),
        },
    })))]);
}

export function displayInteractionContent(record) {
    return toList(delay(() => {
        let children, children_2;
        return append(singleton(content_2(empty(), ofArray([level(empty(), ofArray([item(singleton_1(new Item_Option_1(1, [])), singleton_1((children = ofArray([heading(empty(), singleton_1("Type")), title_1(empty(), singleton_1(getEnumName(enum_type("Omnicv.Diary.Shared.Data.InteractionType", int32_type, [["Other", 0], ["Physical", 1], ["Call", 2], ["VideoCall", 3], ["TextMessage", 4], ["Email", 5], ["Postal", 6]]), record.InteractionType)))]), createElement("div", {
            children: reactApi.Children.toArray(Array.from(children)),
        })))), item(singleton_1(new Item_Option_1(1, [])), singleton_1((children_2 = ofArray([heading(empty(), singleton_1("Duration")), title_1(empty(), singleton_1(secondsToHuman(record.DurationSec)))]), createElement("div", {
            children: reactApi.Children.toArray(Array.from(children_2)),
        }))))])), message(ofArray([new Option_3(0, [new Color_IColor(5, [])]), new Option_3(1, [new Size_ISize(0, [])])]), ofArray([header_1(empty(), singleton_1("Participants")), body(empty(), singleton_1(record.Participants))])), message(empty(), singleton_1(body(empty(), singleton_1(createElement("div", {
            dangerouslySetInnerHTML: {
                __html: Markdown_ToHtml_1BFEAEDB(record.Notes),
            },
        })))))]))), delay(() => ((record.FollowupTime != null) ? singleton(content_2(empty(), toList(delay(() => append(singleton(columns(empty(), ofArray([column(empty(), singleton_1("Follow up:")), column(empty(), toList(delay(() => {
            let copyOfStruct;
            return append(singleton((copyOfStruct = value_2(record.FollowupTime), toString(copyOfStruct))), delay(() => {
                const matchValue = record.FollowupDismissed;
                let matchResult;
                if (matchValue != null) {
                    if (matchValue) {
                        matchResult = 0;
                    }
                    else {
                        matchResult = 1;
                    }
                }
                else {
                    matchResult = 1;
                }
                switch (matchResult) {
                    case 0:
                        return append(singleton(icon(ofArray([new Option_2(0, [new Size_ISize(0, [])]), new Option_2(2, [])]), singleton_1(Fa_i_1(singleton_1(Icons__ToIcon(new Icons(11, []))), [])))), delay(() => singleton(" [Done]")));
                    default:
                        return singleton(defaultOf());
                }
            }));
        })))]))), delay(() => ((record.FollowupNotes != null) ? singleton(columns(empty(), singleton_1(column(empty(), singleton_1(value_2(record.FollowupNotes)))))) : empty_1()))))))) : empty_1())));
    }));
}

export function displayDiaryEntry(deleteFun, eventInfo, record, wordCount) {
    return displayEntry((Item) => (new Route(11, [Item])), deleteFun, eventInfo, record, record.Tagline, record.Tags, displayDiaryContent, wordCount);
}

export function displayReviewEntry(deleteFun, eventInfo, record, wordCount) {
    return displayEntry((Item) => (new Route(13, [Item])), deleteFun, eventInfo, record, record.Tagline, record.Tags, displayReviewContent, wordCount);
}

export function displayInteractionEntry(deleteFun, eventInfo, record, wordCount) {
    return displayEntry((Item) => (new Route(15, [Item])), deleteFun, eventInfo, record, record.Tagline, record.Tags, displayInteractionContent, wordCount);
}

const diaryRecordData = new DisplayRecordData$1((Item) => (new Route(11, [Item])), (record) => record.Tagline, (record_1) => record_1.Tags, displayDiaryContent, false);

const reviewRecordData = new DisplayRecordData$1((Item) => (new Route(11, [Item])), (record) => record.Tagline, (record_1) => record_1.Tags, displayReviewContent, false);

const interactionRecordData = new DisplayRecordData$1((Item) => (new Route(11, [Item])), (record) => record.Tagline, (record_1) => record_1.Tags, displayInteractionContent, false);

export function displayUnifiedEntries(entries, disabled, deleteFun) {
    let isMultiDayList;
    if (isEmpty(entries)) {
        isMultiDayList = false;
    }
    else {
        const first = head(entries)[0];
        isMultiDayList = (days(op_Subtraction(date_1(last_1(entries)[0].EventTimestamp), first.EventTimestamp)) > 1);
    }
    return toList(delay(() => {
        let children;
        return append(singleton((children = toList(delay(() => ((isEmpty(entries) && !disabled) ? singleton(closeable("No entries found", new Class(1, []))) : empty_1()))), react.createElement("div", {}, ...children))), delay(() => {
            let children_2;
            return !isEmpty(entries) ? singleton((children_2 = map((entry) => {
                const wordCount = countWordsInRecord(entry[1]) | 0;
                switch (entry[1].tag) {
                    case 0:
                        return displayDiaryEntry(deleteFun, entry[0], entry[1].fields[0], wordCount);
                    case 2:
                        return displayInteractionEntry(deleteFun, entry[0], entry[1].fields[0], wordCount);
                    default:
                        return displayReviewEntry(deleteFun, entry[0], entry[1].fields[0], wordCount);
                }
            }, entries), react.createElement("div", {
                disabled: disabled,
            }, ...children_2))) : singleton(defaultOf());
        }));
    }));
}

function displayReminder(markDismissedFun, reminder) {
    return columns(singleton_1(new Option_4(3, [])), toList(delay(() => {
        const now = common.getNow();
        let patternInput;
        const followupDate = value_2(reminder.FollowupTime);
        const time = formatDistance(followupDate, now, {});
        patternInput = ((compare(followupDate, now) >= 0) ? [time + " in the future", ""] : [time + " overdue", "overdue-reminder"]);
        return append(singleton(column(singleton_1(new Option_5(2, [patternInput[1]])), ofArray([reminder.Tagline, ": ", patternInput[0]]))), delay(() => append(singleton(column(singleton_1(new Option_5(4, [singleton_1(new Modifier_IModifier(3, [new Screen(0, []), new TextSize_Option(6, [])]))])), singleton_1(value_2(reminder.FollowupNotes)))), delay(() => singleton(column(empty(), ofArray([button(ofArray([new Option_1(0, [new Color_IColor(4, [])]), new Option_1(5, []), new Option_1(18, [(_arg) => {
            Router_modifyLocation(new Route(9, [dateToStrForDiary(reminder.EventTimestamp)]));
        }])]), singleton_1(icon(singleton_1(new Option_2(0, [new Size_ISize(0, [])])), singleton_1(Fa_i_1(singleton_1(Icons__ToIcon(new Icons(12, []))), []))))), button(ofArray([new Option_1(0, [new Color_IColor(5, [])]), new Option_1(5, []), new Option_1(18, [(_arg_1) => {
            if (window.confirm(`${"Confirm dismissal"}:
${"Dismiss this reminder?"}`)) {
                (() => {
                    markDismissedFun(reminder.Id);
                })();
            }
        }])]), singleton_1(icon(singleton_1(new Option_2(0, [new Size_ISize(0, [])])), singleton_1(Fa_i_1(singleton_1(Icons__ToIcon(new Icons(11, []))), [])))))])))))));
    })));
}

export function displayReminders(entries, disabled, markDismissedFun) {
    return toList(delay(() => {
        let children;
        return !isEmpty(entries) ? singleton(box$0027(empty(), ofArray([h3(empty())(singleton_1("Reminders")), h4(singleton_1(new Option_6(6, [])))(singleton_1(`Total ${length(entries)} reminders`)), (children = map((reminder) => displayReminder(markDismissedFun, reminder), entries), react.createElement("div", {
            disabled: disabled,
        }, ...children))]))) : singleton(defaultOf());
    }));
}

